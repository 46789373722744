<template>
  <div>
    <Tab :list="tabs"></Tab>

    <router-view> </router-view>
  </div>
</template>
<script>
import Tab from "@/components/Tab";
export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabs: [
        {
          title: "应急预案",
          path: "/drainage/emergancy/riskPlan", //根据router中的path全路径
        },
        {
          title: "应急队伍",
          path: "/drainage/emergancy/riskMember",
        },
        {
          title: "应急物资",
          path: "/drainage/emergancy/riskMaterial",
        },
        {
          title: "应急培训",
          path: "/drainage/emergancy/riskTraining",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
